import orderBy from 'lodash/orderBy';
import { otelClient } from 'api';

const formatKeys = (data) => {
  if (!data) return [];
  return data.reduce((arr, item) => {
    if (item.keys && item.keys.length) {
      arr = [
        ...arr,
        ...item.keys.map((filter) => ({
          type: item.type,
          field: filter,
          order: item.type === 'root' ? 0 : 1,
        })),
      ];
    }
    return orderBy(arr, ['order', 'field'], ['asc', 'asc']);
  }, []);
};

// TODO update keys, values api to pass type param
export const fetchLogKeys = (query) =>
  otelClient
    .get(`logs/keys`, {
      params: query,
    })
    .then(formatKeys);

export const fetchLogValues = (query) => otelClient.post('logs/values', query);

export const fetchKeys = ({ dataset, query }) =>
  otelClient
    .get(`keys/${dataset}`, {
      params: query,
    })
    .then(formatKeys);

export const fetchValues = ({ dataset, query }) =>
  otelClient.post(`values/${dataset}`, query);

export const fetchTraces = (query, listSpans = false) =>
  otelClient.post(`traces/search${listSpans ? '?listSpans=1' : ''}`, query);

export const getTrace = ({ id, query }) =>
  otelClient.get(`traces/${id}`, {
    params: query,
  });

export const getTraceLogs = ({ id, query }) =>
  otelClient.get(`traces/${id}/logs`, { params: query });

export const runAggregateQuery = (table, query) =>
  otelClient.post(`agg/${table}`, query);

export const runMultiAggregateQuery = (table, query) =>
  otelClient.post(`agg/multi/${table}`, query);

export const fetchLogs = (query) => otelClient.post('logs/search', query);

export const fetchMetricKeys = (query) =>
  otelClient
    .get(`metrics/keys`, {
      params: query,
    })
    .then(formatKeys);

export const fetchMetricValues = (query) =>
  otelClient.post('otel', 'metrics/values', query);

export const getRumSessions = (query) => otelClient.post(`rum/sessions`, query);

export const getSessionRecordingEvents = (query) =>
  otelClient.post('rum/session-recordings', query);

export const getRumTrace = (query) => otelClient.post(`rum/traces`, query);

export const getPages = (query) => otelClient.post(`rum/pages`, query);